body {
    font-family: 'Arial', sans-serif;
    text-align: center;
    color: #ecc037;
    background-color: #396376;
/*    margin: 0px;
    padding: 0px;*/
}
h1 {
    color: #d4af37; /* Золотистый цвет для заголовка */
}
h2 {
    color: #ffd334; /* Коричневый цвет для подзаголовков */
}

label {
    display: block; /* Отображение метки как блочный элемент */
    margin-top: 10px; /* Отступ сверху */
}

#round-info {
    margin-top: 20px;
    font-size: 18px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.battle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../public/images/bg-battle.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
}

.tavern-container {
    background-image: url('../../public/images/bg-tavern.png'); /*Путь к изображению */
    background-repeat: no-repeat; /* Изображение не будет повторяться */
    background-position: center; /* Изображение будет центрировано */
    height: 100vh; /* Высота на весь экран, можно изменить по необходимости */
    width: 100%; /* Ширина на весь экран */
}

.clicker-container {
    background-image: url('../../public/images/bg-clicker.png'); /*Путь к изображению */
    background-size: cover; /*Изображение будет растянуто, чтобы покрыть весь фон */
    background-repeat: no-repeat; /* Изображение не будет повторяться */
    background-position: center; /* Изображение будет центрировано */
    height: 100vh; /* Высота на весь экран, можно изменить по необходимости */
    width: 100%; /* Ширина на весь экран */
}

.button-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.fixed-nav {
    position: fixed;
    bottom: 5px; /* Отступ от нижней части экрана */
    left: 0;
    right: 0;
    z-index: 1000; /* Убедитесь, что навигация находится поверх других элементов */
    background-color: #396376;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
    body {
        overflow: hidden; /* Отключает прокрутку */
    }

    #round-info {
        font-size: 16px; /* Уменьшаем размер шрифта */
        padding: 8px; /* Уменьшаем отступы */
    }

    .button-container {
        flex-direction: column; /* Кнопки располагаем вертикально */
        align-items: center; /* Центрируем кнопки */
    }

    .fixed-nav {
        position: fixed; /* Фиксированное положение */
        bottom: 0; /* Прикрепляем к нижней части экрана */
        left: 0; /* Прикрепляем к левому краю */
        right: 0; /* Прикрепляем к правому краю */
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Тень для визуального отделения */
        z-index: 1000; /* Убедитесь, что навигация находится поверх других элементов */
        padding: 10px; /* Отступы внутри навигации */
        background-color: #396376;
    }
}
