.npc-heroes, .player-heroes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.hero-card-grid {
    padding-top: 5%;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
    .npc-heroes, .player-heroes {
        flex-direction: row; /* Изменяем на горизонтальное расположение */
        justify-content: center; /* Центрируем карточки */
        flex-wrap: wrap; /* Позволяем перенос карточек на следующую строку */
    }

    .hero-card-grid {
        padding-top: 15%;
    }
}
