.hero {
    border: 2px solid #8b4513;
    border-radius: 22px;
    padding: 0px;
    margin: 10px;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #fff, #e0e0e0);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    width: auto;
    overflow: hidden;
    position: relative;
    color: white;
}

.hero:hover {
    transform: scale(1.05);
}

.hero img {
    width: 170px;
    height: auto;
    border-radius: 22px;
}

.hero .info {
    text-align: left;
}

.hero p {
    margin: 0;
    font-weight: bold;
}

.hero ul {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
}

.hero li {
    margin: 5px 0;
}

.attack, .health, .name {
    position: absolute;
    font-weight: bold;
    border-radius: 15px;
    width: 19px;
    height: 19px;
}

.attack {
    bottom: 12px;
    left: 17px;
    background-color: darkred;
}

.health {
    bottom: 12px;
    right: 14px;
    background-color: green;
}

.name {
    bottom: 8px;
    left: 42%;
    transform: translateX(-42%);
}

select {
    display: none; /* Скрываем выпадающий список */
}
.selected-enemy {
    font-weight: bold; /* Жирный шрифт для выбранного врага */
    margin-top: 5px; /* Отступ сверху для текста */
}
.highlight {
    border-color: red; /* Красная рамка для подсветки */
    box-shadow: 0 0 15px rgba(255, 0, 0, 1); /* Тень для подсветки */
}

.highlight-green {
    border-color: lightgreen; /* Зеленая рамка для подсветки */
    box-shadow: 0 0 15px rgba(94, 186, 94, 1); /* Тень для подсветки */
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
    .hero {
        flex-direction: column; /* Вертикальное расположение элементов карточки */
        align-items: center; /* Центрируем элементы карточки */
        margin: 5px; /* Уменьшаем отступы между карточками */
    }

    .hero img {
        width: 100px; /* Уменьшаем ширину изображения */
    }

    .attack, .health, .name {
        font-size: 12px; /* Уменьшаем размер шрифта */
        width: 15px; /* Уменьшаем ширину */
        height: 15px; /* Уменьшаем высоту */
    }

    .attack {
        bottom: 5px;
        left: 8px;
        background-color: darkred;
    }

    .health {
        bottom: 6px;
        right: 7px;
        background-color: green;
    }

    .name {
        bottom: 5px;
        left: 40%;
        transform: translateX(-50%);
    }
}
