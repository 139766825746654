.attack-button {
    padding: 10px 20px;
    margin-top: 4px;
    background-color: #ecc035; /* Золотистый цвет кнопки */
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s; /* Плавный эффект при наведении */
}

.attack-button:hover {
    background-color: #b8860b; /* Темнее при наведении */
}

.attack-button2 {
    padding: 10px 20px;
    margin-top: 4px;
    margin: 5px;
    background-color: #ecc035; /* Золотистый цвет кнопки */
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s; /* Плавный эффект при наведении */
}

.attack-button2:hover {
    background-color: #b8860b; /* Темнее при наведении */
}

.attack-button.disabled {
    background-color: #d3d3d3; /* Серый цвет для заблокированной кнопки */
    cursor: not-allowed; /* Изменяем курсор на "нельзя" */
    color: #a9a9a9; /* Более светлый цвет текста */
}

/* Медиа-запрос для мобильных устройств */
/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
    .attack-button {
        padding: 8px 16px; /* Уменьшаем отступы кнопки */
        font-size: 14px; /* Уменьшаем размер шрифта */
        width: 100%; /* Устанавливаем фиксированную ширину кнопки */
        max-width: 100%; /* Ограничиваем максимальную ширину кнопки */
    }
    .attack-button2 {
        padding: 8px 16px; /* Уменьшаем отступы кнопки */
        font-size: 14px; /* Уменьшаем размер шрифта */
        width: 100px; /* Устанавливаем фиксированную ширину кнопки */
        max-width: 100%; /* Ограничиваем максимальную ширину кнопки */
        margin: 5px;
    }
}
