/* Сброс стилей по умолчанию для всех элементов */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Учитываем отступы и границы в ширине и высоте */
}

/* Удаляем стандартные стили для списков */
ul, ol {
    list-style: none; /* Убираем маркеры и номера */
}

/* Убираем стандартные стили для ссылок */
a {
    text-decoration: none; /* Убираем подчеркивание */
    color: inherit; /* Устанавливаем цвет текста по умолчанию */
}

/* Убираем стандартные стили для таблиц */
table {
    border-collapse: collapse; /* Убираем отступы между ячейками */
    width: 100%; /* Устанавливаем ширину таблицы на 100% */
}

/* Убираем стандартные стили для форм */
input, button, textarea, select {
    font-family: inherit; /* Устанавливаем шрифт по умолчанию */
    font-size: inherit; /* Устанавливаем размер шрифта по умолчанию */
    line-height: inherit; /* Устанавливаем высоту строки по умолчанию */
}

/* Убираем стандартные стили для изображений */
img {
    max-width: 100%; /* Устанавливаем максимальную ширину на 100% */
    height: auto; /* Устанавливаем автоматическую высоту */
}

/* Убираем стандартные стили для заголовков */
h1, h2, h3, h4, h5, h6 {
    font-weight: normal; /* Убираем жирный шрифт */
}
